@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro'),
    url('./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Light'),
    url('./fonts/Source_Sans_Pro/SourceSansPro-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: local('Source Sans Pro Semibold'),
    url('./fonts/Source_Sans_Pro/SourceSansPro-Semibold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Dosis';
  src: local('Dosis Medium'), url('./fonts/Dosis/Dosis-Medium.ttf');
  font-weight: 500;
}

html,
body,
div,
h2,
h4,
span,
i,
a {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  position: relative;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

body::before {
  content: '';
  position: absolute;
  top: 20%;
  left: 40%;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  background-image: linear-gradient(to top, #e3e7f0 0%, #f7f8fc 100%);
  z-index: 10;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #d8deec 0%, #ffffff 100%);
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: -520px;
    left: -180px;
    width: 630px;
    height: 630px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #ff18be 0%, #fe3032 100%);
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -250px;
    right: -130px;
    width: 450px;
    height: 450px;
    border-radius: 50%;
    background-image: linear-gradient(to top, #21d4ff 0%, #3151fe 100%);
  }
}

.nav {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 20;
  &__item {
    display: block;
    padding: 10px 60px 10px 25px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: #999a9e;
    font-weight: 300;
    background: linear-gradient(to right, #eef2f5 0%, #fbfbfb 100%);
    transition: all 1s ease;
    cursor: pointer;
    opacity: 0;
    animation: fadeInLeft 1s forwards;

    i {
      margin-right: 10px;
      color: #b2b3b7;
    }

    &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
      //box-shadow: -8px 5px 15px rgba(0, 0, 0, 0.05)
    }

    &:nth-child(1) {
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      animation-delay: 1s;
    }

    &:nth-child(3) {
      animation-delay: 1.5s;
    }

    &:nth-child(4) {
      animation-delay: 2s;
    }
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.profile {
  position: absolute;
  left: 90px;
  bottom: 100px;
  color: #000000;
  z-index: 20;

  &__name {
    position: relative;
    font-family: 'Dosis', 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
    letter-spacing: 2px;
    font-size: 18px;
    font-weight: normal;
    cursor: pointer;
    span {
      font-weight: bold;
    }
    .popover {
      display: none;
      position: absolute;
      bottom: 42px;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.85);
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      z-index: 20;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: -7px;
        margin-left: -7px;
        border-top: 7px solid rgba(0, 0, 0, 0.85);
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
      }
    }

    &:hover {
      .popover {
        display: block;
        animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
      }
    }
  }
  &__work {
    margin: 10px 0;
    font-size: 32px;
    font-weight: 300;
  }
  &__social-links {
    a {
      display: inline-block;
      margin-right: 10px;
      color: #999a9e;
      transition: all 1s ease;

      &:hover {
        color: #f32975;
      }
    }
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}

@-moz-keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}

@-ms-keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}

.footer {
  //position: absolute;
  //bottom: 20px;
  //left: 50%;
  //transform: translateX(-50%);
  margin: 20px 0;
  color: #c4c4c5;
  a {
    display: inline-block;
    color: #c4c4c5;
    font-size: 12px;
    transition: all 1s ease;
    &:hover {
      color: #8b8c90;
    }
  }
}

@media screen and (max-width: 320px) {
  .container {
    &::before {
      width: 350px;
      height: 350px;
      top: -255px;
      left: -180px;
    }
    &::after {
      width: 150px;
      height: 150px;
      bottom: -70px;
      right: -60px;
    }
  }
  .nav {
    &__item {
      padding: 10px 40px 10px 25px;
    }
  }
  .profile {
    left: 20px;
    &__name {
      font-size: 16px;
    }
    &__work {
      font-size: 30px;
    }
  }
  .footer {
    display: none;
  }
}

@media screen and (max-width: 620px) and (min-width: 321px) {
  .container {
    &::before {
      width: 400px;
      height: 400px;
      top: -290px;
      left: -170px;
    }
    &::after {
      width: 200px;
      height: 200px;
      bottom: -100px;
      right: -85px;
    }
  }
  .profile {
    left: 30px;
  }
  .footer {
    display: none;
  }
}
